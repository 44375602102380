import React, { useEffect, useState } from 'react';
import Header from './Header';
import Containers from './Containers';
import Login from './Login';
import Loading from './Loading'; // import the Loading component
import useTestAPI from './TestAPI';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLoading, setShowLoading] = useState(false); // set initial state to false
  const [retryCount, setRetryCount] = useState(0);
  const {error} = useTestAPI(retryCount);

  useEffect(() => {
    if (isLoggedIn) { // check if user is logged in
      setShowLoading(true); // show loading component
      const timer = setTimeout(() => {
        setShowLoading(false); // hide loading component after 2 seconds
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        console.log('API data collation error: Retrying in one minute...');
        setRetryCount(retryCount + 1);
      }, 60000); // Retry after 1 minute
    }
  }, [error, retryCount]);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  if (!isLoggedIn) {
    return <Login onLogin={handleLogin} />;
  }

  if (showLoading) {
    return <Loading />;
  }

  if (error) {
    return <div style={{margin: '20px'}}><h5>API data collation error: {error}...
    <br></br>Retrying connection in one minute... 
    <br></br>Retries: {retryCount}
    <br></br>
    <br></br>If the problem persists, restart and try logging in again.</h5></div>;
  }

  return (
    <div>
      <Header />
      <Containers />
    </div>
  );
}

export default App;