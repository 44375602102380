import React from "react";
import './CSS/Detailed.css';

const Detailed = ({ selectedSystem }) => {
  if (!selectedSystem) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '18px', color: 'white' }}>No location selected. Please select a BESS.</div>;
  }

  function roundToDecimal(value) {
    if (value === null) {
      console.error('Null value found');
      return value;
    }
  
    if (typeof value === 'number') {
      return Math.round(value * 10) / 10; // Round to 1 decimal place
    }
    
    if (typeof value === 'boolean') {
      return value.toString();
    }

    return value;
  }

  
  function formatKey(key) {
    const acronyms = ['ac', 'dc', 'uid', 'vid', 'soc', 'soh', 'id', 'ip', 'pv'];
    return key
      .split('_') // split the string into words
      .map(word => {
        if (acronyms.includes(word.toLowerCase())) { // if the word is an acronym
          return word.toUpperCase(); // uppercase the whole word
        }
        return word.charAt(0).toUpperCase() + word.slice(1); // otherwise, just capitalise the first letter
      })
      .join(' '); // join the words back together, separated by a space
  }
  
  
  function isMatch(key, value) {
    const keyLowercase = key.toLowerCase();
    const matchingKeywords = ["warnings", "faults", "alarms"];
    const matchingValues = [0, false, '0', 'false'];

    const matchingKeyword = matchingKeywords.find(kw => keyLowercase.includes(kw));
    const valueMatches = matchingValues.includes(value);

    return { keyMatches: Boolean(matchingKeyword), matchingKeyword, valueMatches };
  }

  function getBgColor({ keyMatches, matchingKeyword, valueMatches }) {
    if (!keyMatches) return 'transparent';

    if (valueMatches) return 'lightgreen';

    switch (matchingKeyword) {
      case 'faults':
        return 'lightpink';
      case 'alarms':
        return 'moccasin';
      case 'warnings':
        return 'lightyellow';
      default:
        return '#08436d';
    }
  }
  
  // Define the desired row keys and sort them
  const mainData = [
    'controller_1_system_name',
    'controller_1_system_type',
    'controller_1_ip_address_network',
    'battery_1_soc',
    'battery_1_soh',
    'battery_1_max_capacity',
    'test_no_work',
    'inverter_1_heartbeat',
    'test_this_should_not_show',
    'logging_1_faults',
    'logging_1_alarms',
    'logging_1_warnings',
  ];

  const roundedApiData = {};
  Object.keys(selectedSystem.apiData).forEach(key => {
    roundedApiData[key] = roundToDecimal(selectedSystem.apiData[key]);
  });

  const timestamp = new Date(selectedSystem.apiData.timestamp);
  const dateRow = {
    label: 'Date',
    value: timestamp.toLocaleDateString()
  };
  const timeRow = {
    label: 'Time',
    value: timestamp.toLocaleTimeString()
  };

  // segregate keys into their respective categories
  const keys = Object.keys(roundedApiData);
  const faultsAlarmsWarningsKeys = keys.filter(key => {
    const match = isMatch(key, roundedApiData[key]);
    return match.keyMatches && !match.valueMatches;
  });

  // All keys are considered for 'All Other Data'
  const otherDataKeys = keys;



  return (
    <div className="detailed-container">
      <table className="data-table">
        <tbody>
          <tr>
            <td colSpan="2" style={{ textAlign: 'center', color: 'white', background: '#063455', fontSize: '14px', border: '2px solid white' }}><strong>Last BESS Data - {dateRow.value} @ {timeRow.value}</strong></td>
          </tr>

          {faultsAlarmsWarningsKeys.length > 0 && (
            <tr><td colSpan="2" style={{ textAlign: 'center', color: 'white', background: '#063455', fontSize: '14px', border: '2px solid white' }}><strong>Faults / Alarms - {selectedSystem.title}</strong></td></tr>
          )}

          {faultsAlarmsWarningsKeys.map((key) => {
            const match = isMatch(key, roundedApiData[key]);
            return (
              <tr key={key}>
                <td>{formatKey(key)}</td>
                <td style={{backgroundColor: getBgColor(match)}}>{roundedApiData[key]}</td>
              </tr>
            );
          })}

          {mainData.length > 0 && (
              <tr><td colSpan="2" style={{ textAlign: 'center', color: 'white', background: '#063455', fontSize: '14px', border: '2px solid white' }}><strong>Main Data - {selectedSystem.title}</strong></td></tr>
            )}

            {mainData.map((key) => {
              if (keys.includes(key)) {
                const match = isMatch(key, roundedApiData[key]);
                return (
                  <tr key={key}>
                    <td>{formatKey(key)}</td>
                    <td style={{backgroundColor: getBgColor(match)}}>{roundedApiData[key]}</td>
                  </tr>
                );
              }
              return null;
            })}


            <tr><td colSpan="2" style={{ textAlign: 'center', fontSize: '14px', color: 'white', background: '#063455', border: '2px solid white' }}><strong>
            Weather - {selectedSystem.title} @ {new Date(selectedSystem.weatherData.timestamp).toLocaleTimeString(undefined, 
            { hour: '2-digit', minute: '2-digit'})}
            </strong></td></tr>
            <tr>
              <td>Description</td>
              <td>{selectedSystem.weatherData.weather[0].description.charAt(0).toUpperCase() + selectedSystem.weatherData.weather[0].description.slice(1)} </td>
            </tr>

            <tr>
              <td>Temperature</td>
              <td>{Math.round(selectedSystem.weatherData.main.temp)} °C</td>
            </tr>

            <tr>
              <td>Humidity</td>
              <td>{Math.round(selectedSystem.weatherData.main.humidity)} %</td>
            </tr>

            <tr>
              <td>Pressure</td>
              <td>{Math.round(selectedSystem.weatherData.main.pressure)} Pa</td>
            </tr>

            <tr>
              <td>Wind Deg</td>
              <td>{Math.round(selectedSystem.weatherData.wind.deg)} °</td>
            </tr>

            <tr>
              <td>Wind Speed</td>
              <td>{Math.round(selectedSystem.weatherData.wind.speed)} m/s</td>
            </tr>

            <tr>
              <td>Sunrise</td>
              <td>{new Date(selectedSystem.weatherData.sys.sunrise * 1000).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit'})} am</td>
            </tr>

            <tr>
              <td>Sunset</td>
              <td>{new Date(selectedSystem.weatherData.sys.sunset * 1000).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit'})} pm</td>
            </tr>


          {otherDataKeys.length > 0 && (
            <tr><td colSpan="2" style={{ textAlign: 'center', fontSize: '14px', color: 'white', background: '#063455', border: '2px solid white' }}><strong>All BESS Data - {selectedSystem.title}</strong></td></tr>
          )}

          {otherDataKeys.map((key) => {
            const match = isMatch(key, roundedApiData[key]);
            return (
              <tr key={key}>
                <td>{formatKey(key)}</td>
                <td style={{backgroundColor: getBgColor(match)}}>{roundedApiData[key]}</td>
              </tr>
            );
          })}

        </tbody>
      </table>
    </div>
  );
}

export default Detailed;
