import React from "react";
import './CSS/Header.css';

function Header() {
    return (
        <header>
        <img className="header-logo" src="./icons/icon_transparent_inverse.png" alt="MSP logo" />
        <h1 className="header-title">B. E. S. S. &nbsp;&nbsp;<span>Monitor</span></h1>
        {/* <button className="header-button">Reset</button>
        <button className="header-button">Cycle</button>
        <button className="header-button">Van</button>
        <div className="header-refresh">⟳</div> */}
        </header>
    );
    }

export default Header;