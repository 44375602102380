import mapboxgl from 'mapbox-gl';
import Locations from './Locations';
import './CSS/Pins.css';

const Pins = (map) => {
    const locations = Locations();
    const bounds = new mapboxgl.LngLatBounds();

    locations.forEach(location => {
        let iconUrl = './icons/battery-0.png';
        const el = document.createElement('div');
        el.className = 'marker';
        el.style.backgroundImage = `url(${iconUrl})`;
        el.style.width = '50px';
        el.style.height = '50px';
        el.style.cursor = 'pointer';
        el.style.backgroundSize = 'contain'; // Ensure the icon maintains its aspect ratio
        el.style.backgroundRepeat = 'no-repeat'; // Prevent the image from repeating

        const markerText = document.createElement('span');
        markerText.innerText = location.text;
        markerText.className = 'marker-text';
        el.appendChild(markerText);

        const marker = new mapboxgl.Marker(el)
            .setLngLat([location.lon, location.lat])
            .addTo(map);

        // Add event listener for the click event
        el.addEventListener('click', () => {
            map.flyTo({
                center: [location.lon, location.lat],
                zoom: 16,
                speed: 1, 
                curve: 1, 
                bearing: 360,
                pitch: 60,
                easing(t) {
                    return t;
                }
            });
        });

        bounds.extend(marker.getLngLat());
    });

    return bounds;
};

export default Pins;
