import { useEffect, useCallback } from 'react';

const useSystemSelection = (setSelectedSystem, weatherData, timeoutRef) => {
  const selectRandomSystem = useCallback(() => {
    const randomIndex = Math.floor(Math.random() * weatherData.length);
    const randomSystem = weatherData[randomIndex];
    setSelectedSystem(randomSystem);
  }, [weatherData, setSelectedSystem]);
  
  useEffect(() => {
    // Commenting out the automatic system selection
    // timeoutRef.current = setTimeout(selectRandomSystem, 60000);
    return () => clearTimeout(timeoutRef.current);
  }, [selectRandomSystem, timeoutRef]);
  
  const handleSystemClick = (system) => {
    setSelectedSystem(system);
    clearTimeout(timeoutRef.current);
    // Commenting out the reset of the automatic system selection
    // timeoutRef.current = setTimeout(selectRandomSystem, 60000);
  };

  return { selectRandomSystem, handleSystemClick, timeoutRef };
};

export default useSystemSelection;
