import mapboxgl from 'mapbox-gl';
import fetchVanData from './VanAPI';

const Van = (map, apiUrl, iconUrl, onUpdate) => {
    let currentMarker = null;

    const moveVan = async () => {
        if (currentMarker) {
            currentMarker.remove();
        }

        const { lat, lon, systemType, lastTime } = await fetchVanData(apiUrl);
        const container = document.createElement('div');
        container.className = 'marker-container';
        container.style.display = 'flex';
        container.style.flexDirection = 'column';
        container.style.alignItems = 'center';

        const el = document.createElement('div');
        el.className = 'marker';
        el.style.backgroundImage = `url(${iconUrl})`;
        el.style.backgroundSize = 'contain';
        el.style.backgroundRepeat = 'no-repeat';
        el.style.width = '32px';
        el.style.height = '32px';
        el.style.cursor = 'pointer';
        el.style.fontWeight = 'bold';
        container.appendChild(el);

        // Create a label element
        const label = document.createElement('div');
        label.style.color = 'black';
        label.style.marginTop = '5px';
        label.style.fontSize = '12px';
        label.style.textAlign = 'center';
        label.style.display = 'none'; // Initially hidden
        label.innerHTML = `
            <table style="border-collapse: collapse; width: 120px;">
                <tr>
                    <td style="border: 1px solid black; padding: 2px;">${systemType}</td>
                </tr>
                <tr>
                    <td style="border: 1px solid black; padding: 2px;">${lastTime}</td>
                </tr>
            </table>
        `;
        container.appendChild(label);

        // Toggle the label on click
        el.addEventListener('click', () => {
            label.style.display = label.style.display === 'none' ? 'block' : 'none';
        });

        currentMarker = new mapboxgl.Marker(container)
            .setLngLat([lon, lat])
            .addTo(map);

        if (onUpdate) {
            onUpdate({ lat, lon });
        }
    };

    moveVan();
    setInterval(moveVan, 20000);
};

const initialiseVans = (map, onUpdate) => {
    const tracker1Url = process.env.REACT_APP_VAN_ADDRESS_TRACKER1; // This is the van
    // const tracker2Url = process.env.REACT_APP_VAN_ADDRESS_TRACKER2; // This is sensor 1
    // const Eco1Url = process.env.REACT_APP_API_ADDRESS_ECO1; // This is Eco1
    // const Eco2Url = process.env.REACT_APP_API_ADDRESS_ECO2; // This is Eco2
    // const Eco3Url = process.env.REACT_APP_API_ADDRESS_ECO3; // This is Eco3
    // // const Eco4Url = process.env.REACT_APP_API_ADDRESS_ECO4; // This is Eco4
    // const Eco5Url = process.env.REACT_APP_API_ADDRESS_ECO5; // This is Eco5
    // const Eco6Url = process.env.REACT_APP_API_ADDRESS_ECO6; // This is Eco6

    const icon1Url = './icons/van-colour.png';
    // const icon2Url = './icons/van-vector.png';
    // const Eco1Icon = './icons/battery-eco1.png';
    // const Eco2Icon = './icons/battery-eco2.png';
    // const Eco3Icon = './icons/battery-eco3.png';
    // // const Eco4Icon = './icons/battery-eco4.png';
    // const Eco5Icon = './icons/battery-eco5.png';
    // const Eco6Icon = './icons/battery-eco6.png';

    Van(map, tracker1Url, icon1Url, onUpdate);
    // Van(map, tracker2Url, icon2Url, onUpdate);
    // Van(map, Eco1Url, Eco1Icon, onUpdate);
    // Van(map, Eco2Url, Eco2Icon, onUpdate);
    // Van(map, Eco3Url, Eco3Icon, onUpdate);
    // // Van(map, Eco4Url, Eco4Icon, onUpdate);
    // Van(map, Eco5Url, Eco5Icon, onUpdate);
    // Van(map, Eco6Url, Eco6Icon, onUpdate);
};

export default initialiseVans;
