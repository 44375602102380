import React, { useEffect, useState, useRef } from 'react';
import { cloudTick, cloudCross, updated, fault, noFault, alarm, noAlarm, 
  soh_0, soh_20, soh_40, soh_60, soh_80, soh_100, soh_none, soc_0, soc_10, 
  soc_20, soc_30, soc_40, soc_50, soc_60, soc_70, soc_80, soc_90, soc_100, 
  soc_none, windIcons } from './Icons';
import useTestAPI from './TestAPI';
import './CSS/System.css';
import useSystemSelection from './SystemSelection'

const System = ({ setSelectedSystem, setTime }) => {
  const { data, isLoading } = useTestAPI();
  const timeoutRef = useRef(null);
  const [weatherData, setWeatherData] = useState([]); 

  useEffect(() => {
    if (isLoading) {
      return; // Don't try to fetch weather data if API data is still loading
    }
    const fetchWeatherData = () => {
      const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      setTime(currentTime);
    
      const processedData = data.map((location) => {
        const response = location.weatherData;
    
        if (response) { 
          const alarmCount = Object.keys(location.apiData).reduce((count, key) => {
            if (key.toLowerCase().includes('alarms') && ['0', false, 0, 'false'].indexOf(location.apiData[key]) === -1) {
              // console.log(`Incrementing count: key=${key}, value=${location.apiData[key]}`);
              return count + 1;
            }
            return count;
          }, 0);
          // console.log(location.title, 'Alarm count:', alarmCount);
        
          const warningCount = Object.keys(location.apiData).reduce((count, key) => {
            if (key.toLowerCase().includes('warnings') && ['0', false, 0, 'false'].indexOf(location.apiData[key]) === -1) {
              //console.log(`Incrementing count: key=${key}, value=${location.apiData[key]}`);
              return count + 1;
            }
            return count;
          }, 0);
          //console.log(location.title, 'Warning count:', warningCount);
        
          const faultCount = Object.keys(location.apiData).reduce((count, key) => {
            if (key.toLowerCase().includes('faults') && ['0', false, 0, 'false'].indexOf(location.apiData[key]) === -1) {
              //console.log(`Incrementing count: key=${key}, value=${location.apiData[key]}`);
              return count + 1;
            }
            return count;
          }, 0);
          //console.log(location.title, 'Fault count:', faultCount);
        
          let soh = Number(location.apiData.battery_1_soh);
          let soc = Number(location.apiData.battery_1_soc);

          const windAngle = response.wind.deg;

          function getWindDirection(angle) {
            // Normalise angle to [0,360)
            const positiveAngle = (angle + 360) % 360;
          
            // Calculate the interval between each direction
            const interval = 360 / 16; // There are 16 wind directions
          
            // Determine the direction based on the angle
            const directionIndex = Math.floor((positiveAngle + interval / 2) / interval);
            const directions = [
              "N", "NNE", "NE", "NEE", 
              "E", "SEE", "SE", "SSE", 
              "S", "SSW", "SW", "SWW", 
              "W", "NWW", "NW", "NNW"
            ];
            const direction = directions[directionIndex % 16]; // Use modulus to wrap around at North
          
            return direction;
          }
          
          const windDirection = getWindDirection(windAngle);
          // Get the corresponding wind direction icon
          const windDirectionIcon = windIcons[windDirection];
          let onlineColour;
          const offlineTrigger = 180000; // 3 minutes
          const lastTimestamp = new Date().getTime() - new Date(location.apiData.timestamp).getTime();
          const isRecent = lastTimestamp > offlineTrigger;
          const hasFaults = faultCount > 0;
          const hasAlarms = alarmCount > 0;
          const hasWarnings = warningCount > 0;
          return {
            ...location,
            name: location.title,
            subtitle: location.subtitle,
            text: location.text,
            city: location.city,
            country: location.country,
            postcode: location.postcode,
            ind: location.index,
            details: location.details,
            battery: location.battery,
            cxp: location.cxp,
            temperature: Math.round(response.main.temp),
            description: response.weather[0].description.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),
            windSpeed: Math.round(response.wind.speed),
            windDeg: response.wind.deg,
            icon: response.weather[0].icon,
            lat: location.lat,
            lon: location.lon,
            feelsLike: response.main.feels_like,
            tempMin: response.main.temp_min,
            tempMax: response.main.temp_max,
            pressure: response.main.pressure,
            humidity: response.main.humidity,
            visibility: response.visibility,
            windGust: response.wind.gust,
            sunrise: response.sys.sunrise,
            sunset: response.sys.sunset,
            clouds: response.clouds.all,
            weatherTime: new Date(response.dt * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            weatherPlace: response.name,
            bessTime: new Date(location.apiData.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit'}),
            type: location.apiData.controller_1_system_type,
            soh: Number(location.apiData.battery_1_soh),
            soc: Number(location.apiData.battery_1_soc),
            timeDiff: Math.round((new Date().getTime() - new Date(location.apiData.timestamp).getTime()) / 60000),
            isOnline: (new Date().getTime() - new Date(location.apiData.timestamp).getTime()) < 120000,
            alarmCount,
            warningCount,
            faultCount,
            windDirection,
            windDirectionIcon,
            // check to see if online time is two minutes or less than current time, if so then online, else offline.
            online: isRecent ? { 
              icon: cloudTick, alt: "Weather icon", width: "30", height: "30" } : { 
              icon: cloudCross, alt: "Weather icon", width: "30", height: "30" },
            onlineColour: (() => {  
              if (hasFaults) {
                onlineColour = 'lightpink';
              } else if (isRecent) {
                onlineColour = 'lightpink';
              } else if (hasAlarms) {
                onlineColour = 'moccasin';
              } else if (hasWarnings) {
                onlineColour = 'lightyellow';
              } else {
                onlineColour = 'lightgreen';
              }
              return onlineColour;
            })(),

            //calculate the difference in time between current time and online time and turn it into minutes.
            // sohImage if it is 0-5 use soh_0 as icon, if it is 5-20 use soh_20 as icon etc.
            sohImage: isNaN(soh) || soh < 0 || soh > 100 ? soh_none :
            soh >= 0 && soh < 5 ? soh_0 :
              soh >= 5 && soh < 20 ? soh_20 :
                soh >= 20 && soh < 40 ? soh_40 :
                  soh >= 40 && soh < 60 ? soh_60 :
                    soh >= 60 && soh < 80 ? soh_80 :
                      soh >= 80 && soh <= 100 ? soh_100 : soh_none,
          socImage: isNaN(soc) || soc < 0 || soc > 100 ? soc_none :
            soc >= 0 && soc < 5 ? soc_0 :
              soc >= 5 && soc < 10 ? soc_10 :
                soc >= 10 && soc < 20 ? soc_20 :
                  soc >= 20 && soc < 30 ? soc_30 :
                    soc >= 30 && soc < 40 ? soc_40 :
                      soc >= 40 && soc < 50 ? soc_50 :
                        soc >= 50 && soc < 60 ? soc_60 :
                          soc >= 60 && soc < 70 ? soc_70 :
                            soc >= 70 && soc < 80 ? soc_80 :
                              soc >= 80 && soc < 90 ? soc_90 :
                                soc >= 90 && soc <= 100 ? soc_100 : soc_none,
        };

        } else {
          console.error('Weather data not found for location:', location);
          return location;
        }
      });

processedData.sort((a, b) => {
  // Assigning scores for each condition
  // faultCount has the highest weight, followed by isOnline, alarmCount, and warningCount
  let scoreA = (a.faultCount * 1000000) + (!a.isOnline ? 10000 : 0) + (a.alarmCount * 100) + (a.warningCount * 1);
  let scoreB = (b.faultCount * 1000000) + (!b.isOnline ? 10000 : 0) + (b.alarmCount * 100) + (b.warningCount * 1);

  // Compare the total scores to determine sort order
  if (scoreA > scoreB) {
    return -1;
  } 
  if (scoreA < scoreB) {
    return 1;
  }

  // If scores are equal, return 0
  return 0;
});

setWeatherData(processedData);
    };
    if (data && data.length > 0) {
      fetchWeatherData();
    }
  }, [data, setTime, isLoading]);

  const { handleSystemClick } = useSystemSelection(setSelectedSystem, weatherData, timeoutRef);

  return (
    <>
      {weatherData.map((location) => {
        return (
          <div key={location.ind} onClick={() => handleSystemClick(location)} className="item">
            <h3 style={{
                borderRight: '1px solid black',
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                fontSize: '13px', 
                margin: '0px', 
                padding: '3px',
                backgroundColor: location.onlineColour
                }}>
                <b>{location.name}</b>
            </h3>
            <h3 style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', alignItems: 'center', fontSize: '13px', margin: '0px', padding: '3px'}}>
                <img 
                    src={location.isOnline ? cloudTick : cloudCross} 
                    alt="Online icon" 
                    width="30" 
                    height="30"
                    style={{ justifySelf: 'center' }}
                />
                <b style={{ fontSize: '13px', justifySelf: 'center', color: 'red'}}>
                  {location.isOnline ? 
                    <img 
                    
                      src={updated}
                      alt="update tick"
                      width="30"
                      height="30"
                      style={{ justifySelf: 'center' }}
                    />
                    : location.timeDiff < 60 ? `${location.timeDiff} min` 
                    : `${Math.floor(location.timeDiff / 60)}hr ${location.timeDiff % 60}min`}
                </b>
            </h3>
                <h3 style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', fontSize: '13px', alignItems: 'center', borderBottom: '1px solid black', borderRight: '1px solid black', margin: '0px', padding: '3px' }}>
                  <img src={`https://openweathermap.org/img/w/${location.icon}.png`} 
                  alt="Weather icon" 
                  width="30" 
                  height="30" 
                  style={{ justifySelf: 'center'}} />
                  <b>{location.temperature} °C</b>
                </h3>
                <h3 style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', fontSize: '13px', alignItems: 'center', borderBottom: '1px solid black', margin: '0px', padding: '3px' }}>
                  <img src={location.windDirectionIcon} 
                  alt="Wind icon" 
                  width="30" 
                  height="30" 
                  style={{ justifySelf: 'center'}} />
                  <b>{location.windSpeed} m/s</b>
                </h3>
              <h3 style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', borderRight: '1px solid black', margin: '0px', padding: '3px'}}>
            <img                    
                    src={ location.faultCount > 0 ? fault : noFault} 
                    alt="Fault Icon" 
                    width="30" 
                    height="30"
                    style={{ justifySelf: 'center', background: location.faultCount > 0 ? 'lightpink' : '' }}
                />
            <img                    
                    src={ location.alarmCount > 0 ? alarm : noAlarm} 
                    alt="Alarm Icon" 
                    width="30" 
                    height="30"
                    style={{ justifySelf: 'center', background: location.alarmCount > 0 ? 'moccasin' : '' }}
                />
            </h3>
            <h3 style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', fontSize: '13px', margin: '0px', padding: '3px'}}>            
            <img src={location.sohImage} alt="SoH icon" height="30px" style={{ justifySelf: 'center' }}/>
            <img src={location.socImage} alt="SoC icon" height="30px" style={{ justifySelf: 'center' }}/>
            </h3>
            </div>
          );
      })}
    </>
  );
  
};
export default System;
