// This file contains the paths to all the icons used in the app

export const cloudTick = './icons/cloud-tick.png';
export const cloudCross = './icons/cloud-cross.png';
export const updated = './icons/updated.png';
export const fault = './icons/fault.png';
export const noFault = './icons/no-fault.png';
export const alarm = './icons/alarm.png';
export const noAlarm = './icons/no-alarm.png';

export const soh_0 = './icons/soh-0.png';
export const soh_20 = './icons/soh-20.png';
export const soh_40 = './icons/soh-40.png';
export const soh_60 = './icons/soh-60.png';
export const soh_80 = './icons/soh-80.png';
export const soh_100 = './icons/soh-100.png';
export const soh_none = './icons/soh-none.png';

export const soc_0 = './icons/SoC-0.png';
export const soc_10 = './icons/SoC-10.png';
export const soc_20 = './icons/SoC-20.png';
export const soc_30 = './icons/SoC-30.png';
export const soc_40 = './icons/SoC-40.png';
export const soc_50 = './icons/SoC-50.png';
export const soc_60 = './icons/SoC-60.png';
export const soc_70 = './icons/SoC-70.png';
export const soc_80 = './icons/SoC-80.png';
export const soc_90 = './icons/SoC-90.png';
export const soc_100 = './icons/SoC-100.png';
export const soc_none = './icons/SoC-none.png';

export const windIcons = {
  N: './icons/Wind-N.png',
  NNE: './icons/Wind-NNE.png',
  NE: './icons/Wind-NE.png',
  NEE: './icons/Wind-NEE.png',
  E: './icons/Wind-E.png',
  SEE: './icons/Wind-SEE.png',
  SE: './icons/Wind-SE.png',
  SSE: './icons/Wind-SSE.png',
  S: './icons/Wind-S.png',
  SSW: './icons/Wind-SSW.png',
  SW: './icons/Wind-SW.png',
  SWW: './icons/Wind-SWW.png',
  W: './icons/Wind-W.png',
  NWW: './icons/Wind-NWW.png',
  NW: './icons/Wind-NW.png',
  NNW: './icons/Wind-NNW.png',
  windNone: './icons/Wind-none.png',
};
