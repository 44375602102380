
function Locations() {
//should we get this initial data from some database or SmartSheet or something?


const locations = [
//   //  { title: 'MSP Prototype - Actual', subtitle: 'Main Office', text: 'PRO', lat: 54.2141, lon: -2.7412, postcode: 'LA6 1RJ', city: 'Lancaster', country: 'England', 
//   //  details: 'Lorem ipsum dolor', battery: 99, cxp: 'CXP-45454'},
//   //  { title: 'MSP REFU - Actual', subtitle: 'Main Office', text: 'REF', lat: 54.2142, lon: -2.7413, postcode: 'LA6 1RJ', city: 'Lancaster', country: 'England', 
//   //  details: 'Lorem ipsum dolor', battery: 99, cxp: 'CXP-45455'},
//   //  { title: 'Cheltenham College', subtitle: 'BESS 1', text: 'CC', lat: 51.901715, lon: -2.117869, postcode: 'GL51 7JS', city: 'Cheltenham', country: 'England', 
//   //  details: 'Lorem ipsum dolor', battery: 89, cxp: 'CXP-23232'},
//   //  { title: 'Eco Park', subtitle: 'BESS 2', text: 'EP', lat: 51.614169, lon: -0.041536, postcode: 'N18 3AG', city: 'London', country: 'England', 
//   //  details: 'Lorem ipsum dolor', battery: 79, cxp: 'CXP-23234'},
//     { title: 'MPK - Nottingham', subtitle: 'BESS 3', text: 'MPK', lat: 52.886998, lon: -1.286579, postcode: 'NG10 3JX', city: 'Nottingham', country: 'England', 
//     details: 'Lorem ipsum dolor', battery: 69, cxp: 'CXP-00908', serial: '00001014'},
//     // { title: 'Ivy Solar', subtitle: 'BESS 4', text: 'IS', lat: 54.2140, lon: -2.7411, postcode: 'PE28 0JG', city: 'King's Lynn', country: 'England', 
//     // details: 'Lorem ipsum dolor', battery: 59, cxp: 'CXP-23234'},
//     { title: 'Continu', subtitle: 'BESS 5', text: 'Con', lat: 51.61806, lon: -3.880132, postcode: 'SA1 8EN', city: 'Swansea', country: 'Wales', 
//     details: 'Lorem ipsum dolor', battery: 49, cxp: 'CXP-00887', serial: '00001009'},
//     // { title: 'E-On', subtitle: 'BESS 6', text: 'EON', lat: 51.574237, lon: -1.812643, postcode: 'SN2 2PN', city: 'Swindon', country: 'England', 
//     // details: 'Lorem ipsum dolor', battery: 39, cxp: 'CXP-45454'},
//     // { title: 'Gridserve - Actual', subtitle: 'BESS 7', text: 'GS', lat: 50.427093, lon: -4.814552, postcode: 'PL26 8UF', city: 'Victoria Junction', country: 'England', 
//     // details: 'Lorem ipsum dolor', battery: 29, cxp: 'CXP-00813'},
//     // { title: 'Gilpin', subtitle: 'BESS 9', text: 'GP', lat: 54.355365, lon: -2.880375, postcode: 'LA23 3NE', city: 'Windermere', country: 'England', 
//     // details: 'Lorem ipsum dolor', battery: 19, cxp: 'CXP-23234'},
//     { title: 'Woodbine', subtitle: 'BESS 10', text: 'WB', lat: 54.007449, lon: -1.098491, postcode: 'YO32 2RH', city: 'York', country: 'England', 
//     details: 'Lorem ipsum dolor', battery: 9, cxp: 'CXP-00853', serial: '00001043'},
//     // { title: 'CorEnergy', subtitle: 'BESS 11', text: 'CE', lat: 53.977446, lon: -1.075004, postcode: 'YO31 8FY', city: 'York', country: 'England', 
//     // details: 'Lorem ipsum dolor', battery: 55, cxp: 'CXP-23232'},
//     // { title: 'AFC', subtitle: 'BESS 12', text: 'AFC', lat: 51.11973386412387, lon: -0.5344767311062251, postcode: 'GU6 8TB', city: 'Dunsfold', country: 'England', 
//     // details: 'Lorem ipsum dolor', battery: 45, cxp: 'CXP-00679'},
//     { title: 'Ryse Energy', subtitle: 'BESS 13', text: 'RE', lat: 53.786259, lon: -2.914046, postcode: 'PR4 2RJ', city: 'Preston', country: 'England', 
//     details: 'Lorem ipsum dolor', battery: 45, cxp: 'CXP-01019', serial: '00261046'},
//     { title: 'MPK - Doncaster', subtitle: 'BESS 14', text: 'MPK', lat: 53.48393, lon: -1.2179, postcode: 'DN12 3AL', city: 'Doncaster', country: 'England', 
//     details: 'Lorem ipsum dolor', battery: 45, cxp: 'CXP-01051_1', serial: '00271046'},
//     // Had to put serial numbers into the cxp to distinguish between systems using the same controller
//     // TODO: Change this to a better way of distinguishing between systems using the same controller
//     // Should I put all serial numbers into the cxp? Or should I put them into a separate field?
//     { title: 'Lynas 0 - CAB500 Site', subtitle: 'BESS 15', text: 'LY0', lat: 55.146255, lon: -6.65053, postcode: 'BT52 2NR', city: 'Coleraine', country: 'Northern Ireland',
//     details: 'Lorem ipsum dolor', battery: 42, cxp: 'cxp-00780_1', serial: '00341067'},
//     { title: 'Lynas 1 - Flex1000', subtitle: 'BESS 16', text: 'LY1', lat: 55.147236, lon: -6.65067, postcode: 'BT52 2NR', city: 'Coleraine', country: 'Northern Ireland',
//     details: 'Lorem ipsum dolor', battery: 28, cxp: 'cxp-00780_1', serial: '00041022'},
//     { title: 'Lynas 2 - Flex1000', subtitle: 'BESS 17', text: 'LY2', lat: 55.146453, lon: -6.64906, postcode: 'BT52 2NR', city: 'Coleraine', country: 'Northern Ireland',
//     details: 'Lorem ipsum dolor', battery: 28, cxp: 'cxp-00780_1', serial: '00061024'},
//     // Added lakes eggs might need to update lat and lon Meg Bank, Stainton, Penrith CA11 0EE CXP-01117/00321066
//     { title: 'Lakes Eggs', subtitle: 'BESS 18', text: 'EGG', lat: 54.644325, lon: -2.79108, postcode: 'CA11 0EE', city: 'Penrith', country: 'England',
//     details: 'Lorem ipsum dolor', battery: 65, cxp: 'CXP-01117', serial: '00321066'},
//     // Added MPK Tamworth MPK Tamworth CXP-01051 0030/1057 06-02-2024 lat: 52.6141, lon: -1.69324 Fazeley, Tamworth B78 3RN
//     { title: 'MPK - Tamworth', subtitle: 'BESS 19', text: 'MPK', lat: 52.6141, lon: -1.69324, postcode: 'B78 3RN', city: 'Tamworth', country: 'England',
//     details: 'Lorem ipsum dolor', battery: 86, cxp: 'CXP-01051_4', serial: '00301057'},
//     // Added Edmonton Park EcoPark CXP00884 0000/1011
//     { title: 'EcoPark - Edmonton', subtitle: 'BESS 20', text: 'ECO', lat: 51.614169, lon: -0.041536, postcode: 'N18 3AG', city: 'London', country: 'England',
//     details: 'Lorem ipsum dolor', battery: 77, cxp: 'CXP-00884', serial: '00001011'},
//     // Added C&G colleges Cheltenham = "CXP-00779_2","build_id":"0000","serial_suffix":"1017" Gloucester = "CXP-00779_1","build_id":"0000","serial_suffix":"1018
//     // Cheltenham = 51.901673835527646, -2.118039346947279
//     { title: 'Cheltenham College', subtitle: 'BESS 21', text: 'CHE', lat: 51.901673835527646, lon: -2.118039346947279, postcode: 'GL51 7SJ', city: 'Cheltenham', country: 'England',
//     details: 'Lorem ipsum dolor', battery: 13, cxp: 'CXP-00779_1', serial: '00001018'},
//     // Gloucester = 51.86116975418126, -2.2561899438755395
//     { title: 'Gloucester College', subtitle: 'BESS 22', text: 'GLO', lat: 51.86116975418126, lon: -2.2561899438755395, postcode: 'GL2 5JQ', city: 'Gloucester', country: 'England',
//     details: 'Lorem ipsum dolor', battery: 58, cxp: 'CXP-00779_2', serial: '00001017'},
//  // Example adding on a new location
//  //   { title: 'Kings Cross Hospital', subtitle: 'BESS 13', text: 'KCH', lat: 56.477757, lon:  -3.880132, postcode: 'DD3 8EA', city: 'Dundee', country: 'Scotland', 
//  //   details: 'Lorem ipsum dolor', cxp: 'CXP-45454'},
// 51.601754758696806, -2.081070188504645
    { title: 'WOMAD 2', subtitle: 'BESS 14', text: 'WM2', lat: 51.601754, lon: -2.081070, postcode: 'SN16 9LL', city: 'Charlton', country: 'England', 
    details: 'Lorem ipsum dolor', cxp: 'CXP-01146', serial: '00431074'},
    { title: 'WOMAD 1', subtitle: 'BESS 15', text: 'WM1', lat: 51.601350, lon: -2.081000, postcode: 'SN16 9LL', city: 'Charlton', country: 'England', 
      details: 'Lorem ipsum dolor', cxp: 'CXP-01146', serial: '00401077'},
  ];
  // Add an index number to each array item in order to cycle through later in the code to prioritise any items that are over the alarm threshold.
  locations.forEach((location, index) => {
    location.index = index;
  });
  

  // Add a random integer between 1 and 100 to each array item in order to simulate a battery charge level.
  //locations.forEach((location) => {
  //   location.battery = Math.floor(Math.random() * 100) + 1;
  // });
return locations;
}

export default Locations;
