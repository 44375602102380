const username = process.env.REACT_APP_API_USERNAME;
const password = process.env.REACT_APP_API_PASSWORD;
const credentials = btoa(`${username}:${password}`);
const headers = new Headers();
headers.append('Authorization', 'Basic ' + credentials);

const fetchVanData = async (apiUrl) => {
    let response = await fetch(apiUrl, { method: 'GET', headers: headers });
    let data = await response.json();
    const timestamp = new Date(data.timestamp);
    const lastTime = `${timestamp.toLocaleDateString(undefined, 
        { year: 'numeric', month: '2-digit', day: '2-digit' })} ${timestamp.toLocaleTimeString(undefined, 
        { hour: '2-digit', minute: '2-digit'})}`;
    
    return {
        lat: parseFloat(data.controller_1_latitude),
        lon: parseFloat(data.controller_1_longitude),
        systemType: data.controller_1_registration,
        lastTime
    };
};

export default fetchVanData;
