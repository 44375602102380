import Locations from "./Locations";
import { useState, useEffect, useCallback } from 'react';

const apiUrl = process.env.REACT_APP_WEATHER_API;

const useTestAPI = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
  
    const CollateData = Locations();
  
    const credentials = btoa(`${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`);
    const headers = new Headers();
    headers.append('Authorization', 'Basic ' + credentials);
  
    const fetchPromises = CollateData.map(location => {
      const endpoint = `https://${process.env.REACT_APP_API_ADDRESS}/${location.cxp}?serial=${encodeURIComponent(location.serial)}`;
      return fetch(endpoint, { headers: headers })
        .then(response => {
          if (!response.ok) {
            throw new Error(`Network response was not ok. Status: ${response.status}, URL: ${endpoint}`);
          }
  
          return response.text();
        })
        .then(text => {
          try {
            return text ? {...location, apiData: JSON.parse(text)} : {...location, apiData: {}};
          } catch (e) {
            console.error(`Error parsing JSON from ${endpoint}: `, e);
            throw new Error(`Error parsing JSON from ${endpoint}: ${e.message}`);
          }
        });
    });
  
    Promise.all(fetchPromises)
      .then(completeData => {
        setIsLoading(false);
        setData(completeData);
        fetchWeather(completeData); // call fetchWeather with the fetched data
      })
      .catch(error => {
        console.error('Error:', error);
        setError(error.toString());
        setIsLoading(false);
      });
  }, []);

  const fetchWeather = async (locationsData) => { // added locationsData parameter
    setIsLoading(true);

    const promises = locationsData.map((location) => { // replaced data with locationsData
      // Only fetch the weather if it hasn't been fetched recently
      if (!location.weatherData || Date.now() - location.weatherData.timestamp > 600000) {
        return fetch(`${apiUrl}&lat=${location.lat}&lon=${location.lon}`).then((response) => response.json());
      } else {
        // If the weather data is recent, resolve immediately with the existing data
        return Promise.resolve(location.weatherData);
      }
    });

    const weatherData = await Promise.all(promises);

    const updatedData = locationsData.map((location, i) => ({ // replaced data with locationsData
      ...location,
      // Add a timestamp to the weather data
      weatherData: { ...weatherData[i], timestamp: Date.now() },
    }));

    setIsLoading(false);
    setData(updatedData);
  };

 
  useEffect(() => {
    fetchData();
    const intervalId1 = setInterval(fetchData, 60000);
  
    return () => {
      clearInterval(intervalId1);
    };
  }, [fetchData]);
  
  useEffect(() => {
    if (data.length) { // Make sure we have data to fetch weather for
      const intervalId2 = setInterval(() => fetchWeather(data), 600000); // Fetch weather data every 10 minutes

      return () => {
        clearInterval(intervalId2); // Clean up interval on unmount
      };
    }
  }, [data]); // this effect depends on the data state

  return { data, isLoading, error, fetchData };
}

export default useTestAPI;
