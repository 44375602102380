import React, { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import Pins from './Pins';
import initialiseVans from './Van'; // Import the initialiseVans function
import './CSS/Map.css';

const MapComponent = ({ selectedSystem }) => {
    const mapContainer = useRef(null);
    const map = useRef(null);

    useEffect(() => {
        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [100, 0],
            zoom: 1,
            pitch: 0
        });

        map.current.on('load', () => {
            // get the bounding box from the Pins component
            const bounds = Pins(map.current);
        
            // Add the vans to the map
            initialiseVans(map.current);

            // calculate the center of bounds
            const center = bounds.getCenter();

            map.current.flyTo({
                essential: true, 
                center: [center.lng, center.lat],
                zoom: 5.5,
                speed: 1,
                curve: 1,
                bearing: 360, 
                // pitch: 60, 
                easing(t) {
                    return t;
                }
            });
        });

        // Cleanup on unmount
        return () => {
            map.current.remove();
        };
    }, []); 

    useEffect(() => {
        if (selectedSystem && map.current) {
            map.current.flyTo({
                essential: true, 
                center: [selectedSystem.lon, selectedSystem.lat],
                zoom: 16,
                speed: 1,
                curve: 1,
                bearing: 360,
                // pitch: 60, 
                easing(t) { 
                    return t;
                }   
            });
        }
    }, [selectedSystem]); 

    return (
        <div ref={mapContainer} style={{ width: '100%', height: '100%' }} />
    );
};

export default MapComponent;
