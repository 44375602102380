import React, { useState } from 'react';
import './CSS/Login.css'; // Import the CSS file

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username.toUpperCase() === process.env.REACT_APP_USERNAME.toUpperCase() && password === process.env.REACT_APP_PASSWORD) {
      onLogin();
    }
  };

  return (
    <div className="login-container">
      <div className="logo-container">
        <img src=".\icons\MSP logo JPG square.jpg" alt="MSP Logo" />
      </div>
      <div className="form-container">
        <form className="login-form" onSubmit={handleSubmit}>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
            className="login-input"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="login-input"
          />
          <input type="submit" value="Login" className="login-submit" />
        </form>
      </div>
    </div>
  );
}

export default Login;
