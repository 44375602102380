// Loading.js

import React from 'react';
import './CSS/Loading.css';

function Loading() {
  return (
    <div className="spinner-container">
      <div className="spinner"></div> 
    </div>
  );
}

export default Loading;