import React, { useState } from "react";
import Map from "./Map";
import Detailed from "./Detailed";
import System from "./System";
import useTestAPI from "./TestAPI";
import "./CSS/Containers.css";



function Containers() {
    const [selectedSystem, setSelectedSystem] = useState(null);
    const [time, setTime] = useState(null);
    const { fetchData, data } = useTestAPI();



    return (
        <div className="container-fluid">
            <div className="row">
                {/* Info container on the left, takes up 25% of the screen */}
                <div className="col-12 col-md-6 col-lg-3 info-container order-md-1 order-1 order-lg-0">
                    <h2>System Status @ {time}</h2>
                    <div id="system-data">
                    <System setSelectedSystem={setSelectedSystem} setTime={setTime} />
                    </div>
                </div>
                {/* Map of the UK from Bing Maps in the center, takes up 50% of the screen */}
                <div className="col-12 col-md-12 col-lg-6 map-container order-md-0 order-0 order-lg-1" >
                    <div id="map-container">
                    <Map selectedSystem={selectedSystem} />
                    </div>
                </div>
                {/* Info container on the right, takes up 25% of the screen */}
                <div className="col-12 col-md-6 col-lg-3 info-container order-md-2 order-2 order-lg-2">
                <h2>Detailed Overview - {selectedSystem ? selectedSystem.name : 'Select System'}</h2>
                    <div id="detailed-data">
                    <Detailed selectedSystem={selectedSystem} fetchData={fetchData} allData={data} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Containers;

